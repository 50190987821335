import axios from "@/utils/axios";
import { head } from "axios";

let id;
export default {
  product: {
    criteria: _ =>axios.get('/api/product/select/condition'),
    zentaoList: params => axios.get('/api/product/getZenTaoList', { params }),
    lists: params => axios.get('/api/product/getOnlineList', { params }),
    create: params => axios.post('/api/product/create', params),
    // queryById: params => axios.get('/api/product/queryById', { params }),
    syncProduct: params => axios.get('/api/product/syncProduct', { params } ),
    isExist: params => axios.get('/api/product/isExist', { params } ),
    promptSku: params => axios.get('/api/product/prompt/sku', { params } ),
    hasProject: params => axios.get('/api/product/hasProject', { params } ),
    editProduct: params => axios.post('/api/product/edit', params)
  },
  project: {
    zentaoList: params => axios.get('/api/project/get/ZenTao/List', { params }),
    lists: params => axios.get('/api/project/getOnlineList', { params }),
    getListById: params => axios.get('/api/project/get/list/byId', { params }),
    getListByProjectId: params => axios.get('/api/project/get/list/by/projectId', { params }),
    updateDingDing: params => axios.post('/api/project/update/DingDing', params),
    update: params => axios.post('/api/project/update', params),
    sendMsg: params => axios.post('/api/project/send/message', params),
    getMember: params => axios.get('/api/project/getProjectMember', { params }),
    create: params => axios.post('/api/project/create', params),
    editProjectMember: params => axios.post('/api/project/editProjectMember', params),
    addProjectMeterialCode: params => axios.post('/api/project/addProjectMeterialCode', params),
    syncProject: params => axios.get('/api/project/syncProject', { params }),
    getProjectBuildList: params => axios.get('/api/project/getProjectBuildList', { params }),
    createCrowd: params => axios.get('/api/project/createCrowd', { params }),
    getBuildInfoById: params => axios.get('/api/project/getBuildInfoById', { params }),
    setRobot: params => axios.post('/api/project/setRobot', params),
    hasMaterial: params => axios.get('/api/project/hasMaterial', { params } ),
    isExist: params => axios.get('/api/project/isExist', { params } ),
    edit: params => axios.post('/api/project/edit', params),
    transfer: params => axios.get('/api/project/transfer', { params } ),
    // 项目负责人，待处理的订单
    handler: params => axios.get('/api/project/handler/order', { params } ),
    isTestLeader: params => axios.get('/api/project/is/testLeader', { params } ),
    createBom: params => axios.post('/api/project/createBom', params),
    editBom: params => axios.post('/api/project/editBom', params),
    delBom: params => axios.get('/api/project/delBom', {params}),
    updateQuoteOrderMaterial: params => axios.post('/api/project/update/quote/order/material/code', params),
    getOrderMaterialList: params => axios.get('/api/project/get/order/material/code/list', { params }),
},
  user: {
    login: params => axios.post('/api/user/login', {...params}),
    getAllRole: _ => axios.get('/api/user/getAllRoleList'),
    getUserList: params => axios.get('/api/user/getUserList', {params }),
    editPassword: params => axios.post('/api/user/editPassword', params),
  },
  category: {
    list: _ =>axios.get('/api/category/lists')
  },
  upload: {
    file: params => axios.post('/api/upload/file', params, { headers: {"Content-Type": "multipart/form-data" }}  )
  },
  material: {
    insert: params => axios.post('/api/product/material/add', params),
    list: params => axios.get('/api/product/material/list', { params }),
    allList: _ => axios.get('/api/product/material/allList'),
    relatedList: params => axios.get('/api/product/material/relatedList', {params}),
    bomTestResult: params => axios.post('/api/product/material/bom/test/result', params),
    getById: params => axios.get('/api/product/material/getById', {params}),
    getDetailByCode: params => axios.get('/api/product/material/getProductMaterialDetail', {params}),
    canEdit: params => axios.get('/api/product/material/canEdit', { params }),
    test: params => axios.get('/api/product/material/test', { params }),
    editInfo: params => axios.get('/api/product/material/edit/info', { params }),
    edit: params => axios.post('/api/product/material/edit', params),
    dispatchTester: params => axios.post('/api/product/material/dispatch/tester', params),
    // createVersion: params => axios.post('/api/product/material/createVersion', {...params}),
    // 开发人员
    devTask: params => axios.get('/api/product/material/dev/task', { params }),
    // 测试人员
    testTask: params => axios.get('/api/product/material/test/task', { params }),
    bomTask: params => axios.get('/api/product/material/bom/task', { params }),
    isSuccess: params => axios.get('/api/product/material/is/success', { params })
  },
  result: {
    create: params => axios.post('/api/association/create', {...params}),
    list: params => axios.get('/api/association/list', { params }),
    queryByProjectId: params => axios.get('/api/association/queryByProjectId', { params }),
    releaseProduct: params => axios.get('/api/association/update/state', { params }),
    download: params => axios.get('/api/association/download', { params }),
  },
  order: {
    create: params => axios.post('/api/order/create', {...params}),
    update: params => axios.post('/api/order/update', {...params}),
    list: params => axios.get('/api/order/list', { params }),
    getListByProjectId: params => axios.get('/api/order/get/list/by/projectId', { params }),
    sendEmail: params => axios.post('/api/order/send/email', {...params}),
    updateState: params => axios.get('/api/order/updateState', { params }),
    urlList: params => axios.get('/api/order/getAnnex', { params }),
    materialList: params => axios.get('/api/order/materialList', { params }),
    getBomListById: params => axios.get('/api/order/getBomListById', { params }),
    getMaterialUrlById: params => axios.get('/api/order/getMaterialUrlById', { params }),
    getMaterialAnnex: params => axios.post('/api/order/getMaterialAnnex', { ...params }),
    getOrderMaterialAnnex: params => axios.get('/api/order/getOrderMaterialAnnex', { params }),
    getEvaluateMaterialAnnex: params => axios.get('/api/order/getEvaluateMaterialAnnex', { params }),
    getOrderAnnex: params => axios.get('/api/order/getOrderAnnex', { params }),
    updateTest: params => axios.get('/api/order/update/test', { params }),
    //  生产人员待处理订单
    handlerOrder: params => axios.get('/api/order/handler/order', { params }),
    sureMaterial: params => axios.get('/api/order/sure/material', { params }),
    urging: params => axios.get('/api/order/urging', { params }),
    unUrging: params => axios.get(`/api/order/unUrging`, { params }),
    returnOrder: params => axios.get(`/api/order/returnOrder`, {params}),
    updateReturnOrder: params => axios.post(`/api/order/update/return/order`, {...params}),
    getEmailTemplate: params => axios.get(`/api/order/getEmailTemplate`, {params}),
    del: params => axios.get(`/api/order/del`, {params}),
  },
  orderMaterialCode: {
    generateSku: params => axios.get('/api/order/material/code/generate/sku', { params }),
    add: params => axios.post('/api/order/material/code/add', {...params}),
    update: params => axios.post('/api/order/material/code/update', {...params}),
    quoteList: params => axios.get('/api/order/material/code/can/quote/list', { params }),
    getListByIds: params => axios.get('/api/order/material/code/get/list/by/ids', { params })
  },
  customer: {
    add: params => axios.post('/api/customer/add', {...params}),
    update: params => axios.post('/api/customer/update', {...params}),
    allList: _ => axios.get('/api/customer/allList'),
    list: params => axios.get('/api/customer/list', { params }),
    exits: params => axios.get('/api/customer/exits', { params }),
  },
  platform: {
    add: params => axios.post('/api/platform/add', {...params}),
    update: params => axios.post('/api/platform/update', {...params}),
    allList: _ => axios.get('/api/platform/allList'),
    list: params => axios.get('/api/platform/list', { params }),
    exits: params => axios.get('/api/platform/exits', { params }),
  },
  chip: {
    add: params => axios.post('/api/chip/add', {...params}),
    update: params => axios.post('/api/chip/update', {...params}),
    allList: _ => axios.get('/api/chip/allList'),
    list: params => axios.get('/api/chip/list', { params }),
    exits: params => axios.get('/api/chip/exits', { params }),
  },
  module: {
    add: params => axios.post('/api/module/add', {...params}),
    update: params => axios.post('/api/module/update', {...params}),
    allList: _ => axios.get('/api/module/allList'),
    list: params => axios.get('/api/module/list', { params }),
    exits: params => axios.get('/api/module/exits', { params }),
  },
  moduleForm: {
    allList: _ => axios.get('/api/module/form/allList'),
  },
  bom: {
    getListByProjectId: params => axios.get('/api/bom/getListByProjectId', { params })
  },
  productCitation: {
    exits: params => axios.get('/api/productCitation/exist', { params }),
    add: params => axios.post('/api/productCitation/add', {...params}),
    getByProjectId: params => axios.get('/api/productCitation/getByProjectId', { params }),
    download: params => axios.get('/api/productCitation/download', { params }),
    updateState: params => axios.get('/api/productCitation/updateState', { params }),
    revoke: params => axios.get('/api/productCitation/revoke', { params }),
    updateById: params => axios.post('/api/productCitation/update', {...params}),
    getById: params => axios.get('/api/productCitation/getById', { params }),
  },
  identification: {
    list: params => axios.get('/api/product/category/identification/list', { params }),
    add: params => axios.get('/api/product/category/identification/add', { params }),
    exits: params => axios.get('/api/product/category/identification/exist', { params }),
  },
  supplier: {
    list: params => axios.get('/api/supplier/list', { params }),
    allList: _ => axios.get('/api/supplier/allList'),
    add: params => axios.post('/api/supplier/add', { ...params }),
    exits: params => axios.get('/api/supplier/exits', { params }),
    update: params => axios.post('/api/supplier/update', { ...params }),
  },
  moduleType: {
    list: params => axios.get('/api/module/type/list', { params }),
    allList: _ => axios.get('/api/module/type/allList'),
    add: params => axios.post('/api/module/type/add', { ...params }),
    exits: params => axios.get('/api/module/type/exits', { params }),
    update: params => axios.post('/api/module/type/update', { ...params }),
  },
  chipType: {
    list: params => axios.get('/api/chip/type/list', { params }),
    allList: _ => axios.get('/api/chip/type/allList'),
    add: params => axios.post('/api/chip/type/add', { ...params }),
    exits: params => axios.get('/api/chip/type/exits', { params }),
    update: params => axios.post('/api/chip/type/update', { ...params }),
  },
  emailRecord: {
    list: params => axios.get('/api/email/record/list', { params }),
  },
  manufacturer: {
    allList: _ => axios.get('/api/manufacturer/allList'),
  },
  evaluate: {
    add: params => axios.post('/api/evaluate/add', { ...params }),
    list: params => axios.get('/api/evaluate/list', { params }),
    update: params => axios.post('/api/evaluate/update', { ...params }),
    getAnnexList: params => axios.get('/api/evaluate/getAnnexList', { params }),
    turnToProduce: params => axios.get('/api/evaluate/turnToProduce', { params }),
    backChooseMaterial: params => axios.get('/api/evaluate/backChooseMaterial', {params }),
    sendEmail: params => axios.post('/api/evaluate/send/email', {...params}),
    downSendEmail: params => axios.post('/api/evaluate/down/sendEmail', {...params}),
  }
}
